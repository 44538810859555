import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {
  teaser,
  teaser__content,
  teaser__media,
} from "./ProjectTeaser.module.scss";

const ProjectTeaser = ({
  children,
  color,
  refEl,
  subtitle,
  slug,
  tags,
  title,
}) => {
  return (
    <article className={teaser}>
      <AniLink paintDrip to={`/${slug}/`} hex={color}>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h2>{title}</h2>
            </div>
            <div className="col-5">
              <div className={teaser__content}>
                <h3>{subtitle}</h3>
                <p>{tags}</p>
              </div>
            </div>
            <div className="col-12" ref={refEl}>
              <div className={teaser__media}>{children}</div>
            </div>
          </div>
        </div>
      </AniLink>
    </article>
  );
};

export default ProjectTeaser;
