import React from "react";
import { motion } from "framer-motion";
import AnimatedText from "./AnimatedText";
import { hero } from "./Hero.module.scss";

const Hero = () => {
  const introHeading = [
    {
      type: "paragraph",
      text: "My name is Lily and I'm a Danish UI/UX Designer with a developer background. Outside of work, I volunteer as a UI/UX Design Lead & Mentor at Women Coding Community, an international non-profit dedicated to empowering diverse women in technology careers.",
    },
  ];
  const opacityVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        delay: 0.1,
        duration: 1.2,
        ease: "easeIn",
      },
    },
  };
  return (
    <motion.section className={hero} initial="hidden" animate="visible">
      <motion.h1 variants={opacityVariants} initial="initial" animate="animate">
        Hi there &#128075;
      </motion.h1>
      {introHeading.map((item, index) => {
        return <AnimatedText {...item} key={index} />;
      })}
    </motion.section>
  );
};

export default Hero;
