import React from "react";
import { Helmet } from "react-helmet";
import { useParallax } from "react-scroll-parallax";
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import Hero from "../components/Hero";
import Layout from "../components/layout";
import ProjectTeaser from "../components/ProjectTeaser";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/base.scss";
import { projects } from "./index.module.scss";
import { siteMetadata } from "../../gatsby-config";

const IndexPage = () => {
  const parallaxElFirst = useParallax({
    translateY: [-3, 5],
  });

  const parallaxElLast = useParallax({
    translateY: [-3, 5],
  });

  return (
    <>
      <Helmet>
        <body className="home" />
        <title>{siteMetadata.title}</title>
        <link rel="canonical" href={siteMetadata.siteUrl} />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        <Hero />
        <motion.section
          className={projects}
          initial={{
            opacity: 0,
            y: 50,
          }}
          animate={{
            opacity: 1,
            y: -10,
            transition: {
              delay: 1.4,
              duration: 0.6,
            },
          }}
        >
          <ProjectTeaser
            color="#1d4f91"
            refEl={parallaxElFirst.ref}
            slug="project-cu"
            title="Design System & CMS"
            subtitle="Columbia University Medical Center"
            tags="UI/UX Design, Drupal CMS, Frontend Development"
          >
            <StaticImage
              src="../images/teaser-cu.webp"
              quality="100"
              loading="eager"
              layout="fullWidth"
              placeholder="dominantColor"
              alt="Desktop and mobile designs of department websites"
            />
          </ProjectTeaser>
          <ProjectTeaser
            color="#C7E7FF"
            refEl={parallaxElFirst.ref}
            slug="project-wcc"
            title="New Platform Design"
            subtitle="Women Coding Community"
            tags="UI/UX Design, Material UI, Design System Engineering"
          >
            <StaticImage
              src="../images/teaser-wcc.webp"
              quality="100"
              loading="eager"
              layout="fullWidth"
              placeholder="dominantColor"
              alt="Desktop and mobile designs of department websites"
            />
          </ProjectTeaser>
          <ProjectTeaser
            color="#a62639"
            refEl={parallaxElLast.ref}
            slug="project-viet-refugee"
            title="Digital Storytelling"
            subtitle="Personal Project: Viet Refugee"
            tags="Parallax Design, React"
          >
            <StaticImage
              src="../images/teaser-viet-refugee.webp"
              quality="100"
              loading="lazy"
              layout="fullWidth"
              placeholder="dominantColor"
              alt="Design design of website"
            />
          </ProjectTeaser>
        </motion.section>
      </Layout>
    </>
  );
};

export default IndexPage;
